/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import i18n from 'i18next';
import { loadStripeTerminal } from '@stripe/terminal-js';
import {
  Modal,
  Button,
  Loader,
  Grid,
  TextArea,
  Dropdown,
  Table,
  Input,
  Icon,
  Radio,
} from 'semantic-ui-react';
import { useInView } from 'react-cool-inview';
import { isEqual, countBy, findLastIndex, cloneDeep, some, sortBy } from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import Checkbox from '../../../components/general/Checkbox/Checkbox';
import { asCurrency } from '../../../utils/NumberFormatter';
import './BookingsScreen.css';
import { useStateValue } from '../../../core/context/StateProvider';
import { Printer } from '../../../utils/Printer';
import { getItemsPageSortedTerminal } from '../../../api/ItemAPI';
import { getAllCategoriesOfShopTerminal } from '../../../api/CategoryAPI';
import { getTables, getTablesOfUser } from '../../../api/ShopAPI';
import {
  createCardPaymentTerminalOther,
  createCardTerminalPayment,
  createCashPaymentTerminal,
  createPayLaterPaymentTerminal,
  createTerminalReaderConnectionToken,
  fulfillCardTerminalPayment,
  getPayLaterBookingOfTable,
  updateLaterPayment,
} from '../../../api/PaymentAPI';
import NumPad from '../../../components/shop/NumPad/NumPad';
import { showToast } from '../../../components/general/Toast/Toast';
import { splitBooking } from '../../../api/BookingAPI';

const BookingsCreateModalMobile = (props) => {
  const [{ shop, user, token }] = useStateValue();
  const { open, onClose, tableId, bookingId, resetBookingId, logoCanvasContext } = props;
  const [isGettingInitialData, setIsGettingInitialData] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [isLoadingPaymentData, setIsLoadingPaymentData] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [preSelectedTableWasSet, setPreSelectedTableWasSet] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [checkedSelectedTableIndex, setCheckedSelectedTableIndex] = useState(-1);
  const [selectedTableIndex, setSelectedTableIndex] = useState(-1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [cart, setCart] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedBookingIndex, setSelectedBookingIndex] = useState(null);
  const [numPadNumChange, setNumPadNumChange] = useState('0');
  const [numPadNumTips, setNumPadNumTips] = useState('0');
  const [cashPaymentModalOptions, setCashPaymentModalOptions] = useState({
    open: false,
    amount: 0,
  });
  const [cardPaymentModalOptions, setCardPaymentModalOptions] = useState({
    open: false,
  });
  const [itemSettings, setItemSettings] = useState({
    totalPrice: 0,
    specialSelections: [],
    notes: '',
    optionGroups: [],
  });
  const [itemDetailsModalOptions, setItemDetailsModalOptions] = useState({
    open: false,
    item: null,
  });
  const [cartModalOptions, setCartModalOptions] = useState({
    open: false,
  });
  const [payLaterModalOptions, setPayLaterModalOptions] = useState({ open: false });
  const [data, setData] = useState({
    tables: [],
    rooms: [],
    allTables: [],
    items: [],
    allItems: [],
    categories: [],
    totalPages: 1,
    currentPage: 1,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [cartData, setCartData] = useState({ toAdd: [], toCancel: [] });
  const inputFieldOptions = {
    change: 'CHANGE',
    tips: 'TIPS',
  };
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef(null);
  const [currentInputField, setCurrentInputField] = useState(inputFieldOptions.change);
  const [terminals, setTerminals] = useState([]);
  const [isDiscoveringTerminal, setIsDiscoveringTerminal] = useState(false);
  const [stripeTerminal, setStripeTerminal] = useState(null);
  const [terminalModalOptions, setTerminalModalOptions] = useState({ open: false });
  const [isSettingUpTerminal, setIsSettingUpTerminal] = useState(false);
  const [isConnectingToTerminal, setIsConnectingToTerminal] = useState(false);
  const [newBookedItems, setNewBookedItems] = useState([]);
  const [oldBookedItems, setOldBookedItems] = useState([]);
  const [bookingsSplitModal, setBookingsSplitModalOptions] = useState({
    open: false,
    booking: null,
  });
  const [isLoadingSplitBooking, setIsLoadingSplitBooking] = useState(false);
  const [isSaveNewBookingDisabled, setIsSaveNewBookingDisabled] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState(i18n.t('allTables'));

  const closeModal = (refreshNeeded) => {
    setCashPaymentModalOptions({
      open: false,
      amount: 0,
    });
    setCardPaymentModalOptions({
      open: false,
    });
    setItemSettings({
      totalPrice: 0,
      specialSelections: [],
      notes: '',
      optionGroups: [],
    });
    setItemDetailsModalOptions({
      open: false,
      item: null,
    });
    setData({
      tables: [],
      items: [],
      allItems: [],
      rooms: [],
      allTables: [],
      categories: [],
      totalPages: 1,
      currentPage: 1,
    });
    setErrorWarningModalOptions({
      open: false,
      message: '',
    });
    setCartModalOptions({ open: false });
    setPayLaterModalOptions({ open: false });
    setTerminalModalOptions({ open: false });
    setIsGettingInitialData(false);
    setIsLoadingInitialData(false);
    setIsLoadingPaymentData(false);
    setPreSelectedTableWasSet(false);
    setIsLoadingBooking(false);
    setIsDiscoveringTerminal(false);
    setIsSettingUpTerminal(false);
    setIsConnectingToTerminal(false);
    setShowSearch(false);
    setSelectedBookingIndex(null);
    setStripeTerminal(null);
    setCurrentInputField(inputFieldOptions.change);
    setSelectedRoom(i18n.t('allTables'));
    setSearchValue('');
    setDropdownOptions([]);
    setNumPadNumChange('0');
    setNumPadNumTips('0');
    setSelectedCategoryIndex(0);
    setSelectedTableIndex(-1);
    setCheckedSelectedTableIndex(-1);
    setHasMoreItems(true);
    setCart([]);
    setCartData({ toAdd: [], toCancel: [] });
    setBookings([]);
    setTerminals([]);
    onClose(refreshNeeded);
  };

  const { observe } = useInView({
    rootMargin: '10px 0px',
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      getMoreItems().then(() => {
        observe();
      });
    },
  });

  const getMoreItems = async () => {
    data.currentPage += 1;
    if (data.currentPage > data.totalPages) {
      setHasMoreItems(false);
    } else {
      let res;
      if (selectedCategoryIndex === 0) {
        res = await getItemsPageSortedTerminal(
          shop._id,
          data.currentPage,
          0,
          'title',
          'ascending',
          null,
          token
        );
      } else {
        res = await getItemsPageSortedTerminal(
          shop._id,
          data.currentPage,
          0,
          'title',
          'ascending',
          data.categories[selectedCategoryIndex]._id,
          token
        );
      }
      setData({
        categories: data.categories,
        items: data.items.concat(res.items),
        allItems: data.allItems,
        tables: data.tables,
        rooms: data.rooms,
        allTables: data.allTables,
        totalPages: res.totalPages,
        currentPage: data.currentPage,
      });
    }
  };

  useEffect(() => {
    if (
      (data.totalPages === 0 || data.totalPages === 1 || data.currentPage === data.totalPages) &&
      hasMoreItems === true
    ) {
      setHasMoreItems(false);
    }
    if (data.currentPage < data.totalPages && hasMoreItems === false) {
      setHasMoreItems(true);
    }
    if (
      data.categories.length === 0 &&
      data.items.length === 0 &&
      data.tables.length === 0 &&
      !isGettingInitialData &&
      open === true
    ) {
      setIsGettingInitialData(true);
      getInitialData();
    }
    if (
      data.categories.length > 0 &&
      data.items.length > 0 &&
      data.tables.length > 0 &&
      tableId !== null &&
      isLoadingInitialData === false &&
      open === true &&
      preSelectedTableWasSet === false &&
      isLoadingBooking === false
    ) {
      const tableIndex = data.tables.findIndex((table) => table._id.toString() === tableId);
      setSelectedTableIndex(tableIndex);
      setPreSelectedTableWasSet(true);
    }
    if (
      open === true &&
      tableId === null &&
      data.categories.length > 0 &&
      data.items.length > 0 &&
      data.tables.length > 0 &&
      selectedTableIndex === -1
    ) {
      setSelectedTableIndex(0);
    }
    if (
      data.categories.length > 0 &&
      data.items.length > 0 &&
      data.tables.length > 0 &&
      checkedSelectedTableIndex !== selectedTableIndex &&
      isLoadingBooking === false &&
      bookings.length === 0 &&
      open === true
    ) {
      setCheckedSelectedTableIndex(selectedTableIndex);
      checkTableForLaterBooking();
      if (selectedTableIndex !== -1) {
        document
          .getElementById('selectedtable')
          .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
    if (shop && shop.terminalReaders && shop.terminalReaders.length > 0 && stripeTerminal == null) {
      createTerminal();
    }
    if (isSaveNewBookingDisabled === true && newBookedItems.length > 0) {
      setIsSaveNewBookingDisabled(false);
    }
    if (isSaveNewBookingDisabled === false && newBookedItems.length === 0) {
      setIsSaveNewBookingDisabled(true);
    }
  });

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onFinalErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
    closeModal(false);
  };

  const checkTableForLaterBooking = async () => {
    try {
      setIsLoadingBooking(true);
      const res = await getPayLaterBookingOfTable(
        token,
        shop._id,
        data.tables[selectedTableIndex]._id
      );
      if (res && res.success) {
        if (res.bookings && res.bookings.length > 0) {
          const cpyBookings = cloneDeep(
            res.bookings.filter(
              (booking) => booking.isRequest === false || booking.isRequest == null
            )
          );
          const cpyBookings2 = cloneDeep(
            res.bookings.filter(
              (booking) => booking.isRequest === false || booking.isRequest == null
            )
          );
          setBookings(cpyBookings);
          const options = res.bookings
            .filter((booking) => booking.isRequest === false || booking.isRequest == null)
            .map((booking, index) => ({
              key: `bk${index}`,
              text: booking.isFromTerminal
                ? `Terminal - ${booking.bookingId} - ${asCurrency(booking.amount / 100)} €`
                : `QR - ${booking.bookingId} - ${asCurrency(booking.amount / 100)} €`,
              value: index,
            }));
          setDropdownOptions(options);
          if (
            bookingId !== null &&
            cpyBookings2.find((booking) => booking.bookingId === bookingId)
          ) {
            const index = cpyBookings2.findIndex((booking) => booking.bookingId === bookingId);
            setSelectedBookingIndex(index);
            setCart(cpyBookings2[index].bookedItems);
            document
              .getElementById('selectedtable')
              .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('savedBookingNotFound'),
        });
      }
      setIsLoadingBooking(false);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('savedBookingNotFound'),
      });
      setIsLoadingBooking(false);
    }
  };

  const calculateChange = (value) => {
    const amount = cart.reduce((sum, item) => sum + (item.isRefund === false ? item.amount : 0), 0);
    const change = value - amount / 100;
    return change;
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (!item._id) {
        newItem._id = item._id;
      }
      if (item.notes) newItem.notes = item.notes;
      if (item.pfand !== null) newItem.pfand = item.pfand;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const getInitialData = async () => {
    try {
      setIsLoadingInitialData(true);
      const itemsResponse = await getItemsPageSortedTerminal(
        shop._id,
        1,
        0,
        'title',
        'ascending',
        null,
        token
      );
      const categoryResponse = await getAllCategoriesOfShopTerminal(shop._id);
      let tablesResponse = null;
      if (user.role === 'owner' || (user.tables.length === 1 && user.tables[0] === 'all')) {
        tablesResponse = await getTables(token);
      } else {
        tablesResponse = await getTablesOfUser(token);
      }
      if (
        itemsResponse &&
        itemsResponse.success &&
        categoryResponse &&
        categoryResponse.success &&
        tablesResponse &&
        tablesResponse.success
      ) {
        const categoriesTmp =
          categoryResponse.categories && categoryResponse.categories.length > 0
            ? categoryResponse.categories.sort((a, b) =>
                getCategoryTranslation(a).localeCompare(getCategoryTranslation(b))
              )
            : [];
        const firstCategory = {
          name: i18n.t('all'),
          key: 0,
          image: 'image',
        };
        const allCategories = [firstCategory].concat(categoriesTmp);
        const tablesTmp = tablesResponse.tables;
        setData({
          categories: allCategories,
          items: itemsResponse.items,
          allItems: itemsResponse.items,
          tables: sortBy(tablesTmp, 'number'),
          rooms: tablesResponse.rooms,
          allTables: sortBy(tablesTmp, 'number'),
          totalPages: itemsResponse.totalPages,
          currentPage: 1,
        });
        data.totalPages = itemsResponse.totalPages;
        setIsLoadingInitialData(false);
      } else {
        setIsGettingInitialData(false);
        setIsLoadingInitialData(false);
      }
    } catch (error) {
      setIsGettingInitialData(false);
      setIsLoadingInitialData(false);
    }
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorBookingsCreateMobile${printerDevice.printer.description}`,
    });
  };

  const printCustomerBon = (booking) => {
    if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      const shopPrinter = room
        ? shop.printers.find(
            (printer) =>
              (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
              printer.isMain === true
          )
        : null;
      if (room && shopPrinter) {
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
        printerDevice.printCustomer(booking, shop, paperSize, null, null, logoCanvasContext);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('noReceiptPrinterForRoomError'),
        });
      }
    } else if (shop.printers.find((printer) => printer.isMain === true)) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      const paperSize =
        shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
      printerDevice.printCustomer(booking, shop, paperSize, null, null, logoCanvasContext);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('noReceiptPrinterError'),
      });
    }
  };

  const payByCardOther = async () => {
    const bookedItems = cart;
    const tableDTO = {
      tableId: data.tables[selectedTableIndex]._id.toString(),
      tableNumber: data.tables[selectedTableIndex].number,
      tableDescription: data.tables[selectedTableIndex].description,
      isTakeAway: data.tables[selectedTableIndex].isTakeAway,
    };
    try {
      setIsLoadingPaymentData(true);
      const res = await createCardPaymentTerminalOther(
        shop._id,
        null,
        null,
        tableDTO,
        bookedItems,
        null,
        bookings.length > 0 && selectedBookingIndex != null
          ? bookings[selectedBookingIndex]._id
          : null,
        cartData.toAdd,
        null,
        Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
        token
      );
      if (res && res.success) {
        if (
          shop.printers &&
          shop.printers.length > 0 &&
          user &&
          user.isPrinting === true &&
          shop.autoPrintCustomer === true
        ) {
          printCustomerBon(res.booking);
        }
        setIsLoadingPaymentData(false);
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentError1'),
        });
        setIsLoadingPaymentData(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('paymentError1'),
      });
      setIsLoadingPaymentData(false);
    }
  };

  const payByCash = async () => {
    const bookedItems = cart;
    const tableDTO = {
      tableId: data.tables[selectedTableIndex]._id.toString(),
      tableNumber: data.tables[selectedTableIndex].number,
      tableDescription: data.tables[selectedTableIndex].description,
      isTakeAway: data.tables[selectedTableIndex].isTakeAway,
    };
    try {
      setIsLoadingPaymentData(true);
      const res = await createCashPaymentTerminal(
        shop._id,
        null,
        null,
        tableDTO,
        bookedItems,
        null,
        bookings.length > 0 && selectedBookingIndex != null
          ? bookings[selectedBookingIndex]._id
          : null,
        cartData.toAdd,
        null,
        Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
        token
      );
      if (res && res.success) {
        if (
          shop.printers &&
          shop.printers.length > 0 &&
          user &&
          user.isPrinting === true &&
          shop.autoPrintCustomer === true
        ) {
          printCustomerBon(res.booking);
        }
        setIsLoadingPaymentData(false);
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentError1'),
        });
        setIsLoadingPaymentData(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('paymentError1'),
      });
      setIsLoadingPaymentData(false);
    }
  };

  const payLater = async () => {
    const bookedItems = cart;
    const tableDTO = {
      tableId: data.tables[selectedTableIndex]._id.toString(),
      tableNumber: data.tables[selectedTableIndex].number,
      tableDescription: data.tables[selectedTableIndex].description,
      isTakeAway: data.tables[selectedTableIndex].isTakeAway,
    };
    try {
      setIsLoadingPaymentData(true);
      let res;
      if (bookings.length > 0 && selectedBookingIndex !== null) {
        res = await updateLaterPayment(
          bookings[selectedBookingIndex]._id,
          bookedItems,
          cartData.toAdd,
          cartData.toCancel,
          token
        );
      } else {
        res = await createPayLaterPaymentTerminal(
          shop._id,
          null,
          null,
          tableDTO,
          bookedItems,
          null,
          token
        );
      }
      if (res && res.success) {
        setIsLoadingPaymentData(false);
        setPayLaterModalOptions({ open: false });
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('createBookingError'),
        });
        setIsLoadingPaymentData(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('createBookingError'),
      });
      setIsLoadingPaymentData(false);
    }
  };

  const selectCategory = async (index) => {
    if (index === 0) {
      const res = await getItemsPageSortedTerminal(
        shop._id,
        1,
        0,
        'title',
        'ascending',
        null,
        token
      );
      setData({
        categories: data.categories,
        items: res.items,
        allItems: data.allItems,
        tables: data.tables,
        allTables: data.allTables,
        rooms: data.rooms,
        totalPages: res.totalPages,
        currentPage: 1,
      });
    } else {
      const res = await getItemsPageSortedTerminal(
        shop._id,
        1,
        0,
        'title',
        'ascending',
        data.categories[index]._id,
        token
      );
      setData({
        categories: data.categories,
        items: res.items,
        allItems: data.allItems,
        tables: data.tables,
        allTables: data.allTables,
        rooms: data.rooms,
        totalPages: res.totalPages,
        currentPage: 1,
      });
    }
    if (data.totalPages === 0 || data.totalPages === 1) setHasMoreItems(false);
    setSelectedCategoryIndex(index);
    document.getElementsByClassName('bookings-create-items-scroll')[0].scrollTo(0, 0);
  };

  const onChangeCheckbox = (event, index, item) => {
    const specialSelections = [...itemSettings.specialSelections];
    specialSelections[index] = event.target.checked;
    const newPrice = event.target.checked
      ? itemSettings.totalPrice + item.specialSelections[index].price
      : itemSettings.totalPrice - item.specialSelections[index].price;
    setItemSettings({
      ...itemSettings,
      totalPrice: newPrice,
      specialSelections: [...specialSelections],
    });
  };

  const onChangeRadio = (event, indexGroup, indexOption, item) => {
    const optionGroups = [...itemSettings.optionGroups];
    optionGroups[indexGroup] = item.optionGroups[indexGroup];
    optionGroups[indexGroup].options[indexOption].checked = true;
    let priceDeduction = 0;
    optionGroups[indexGroup].options.forEach((option, index) => {
      if (option.checked === true && index !== indexOption) {
        // eslint-disable-next-line no-param-reassign
        option.checked = false;
        priceDeduction += option.price;
      }
    });
    const newPrice =
      itemSettings.totalPrice -
      priceDeduction +
      optionGroups[indexGroup].options[indexOption].price;
    setItemSettings({ ...itemSettings, totalPrice: newPrice, optionGroups: [...optionGroups] });
  };

  const renderIngredients = (item) => (
    <>
      <p className="customer-item-screen-textlist">{item.ingredients.join(', ')}</p>
    </>
  );

  const renderAllergens = (item) => (
    <>
      <p className="customer-item-screen-textlist">{item.allergens.join(', ')}</p>
    </>
  );

  const cleanupBookedItems = (bookedItems) => {
    const clean = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.notes) newItem.notes = item.notes;
      if (item.pfand !== null) newItem.pfand = item.pfand;
      return newItem;
    });
    return clean;
  };

  const subtractBookedItem = async (index) => {
    const cartDataCpy = cloneDeep(cartData);
    cartDataCpy.toCancel.push(cart[index]);
    const cartCpy = cloneDeep(cart);
    cartCpy.splice(index, 1);
    setCart(cartCpy);
    const cleanCancel = cleanupBookedItems(cartDataCpy.toCancel);
    const cleanAdd = cleanupBookedItems(cartDataCpy.toAdd);
    const foundIndexCartData = cleanAdd.findIndex((item) =>
      some(cleanCancel, (element) => isEqual(item, element))
    );
    if (foundIndexCartData !== -1) {
      const newAdd = cartDataCpy.toAdd;
      newAdd.splice(foundIndexCartData, 1);
      setCartData({ ...cartData, toAdd: newAdd });
    } else {
      setCartData({ ...cartData, toCancel: cartDataCpy.toCancel });
    }
    if (cartCpy.length === 0) {
      setCartModalOptions({ open: false });
    }
  };

  const addSameBookedItem = async (index) => {
    if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
      const cartDataCpy = cloneDeep(cartData);
      cartDataCpy.toAdd.push(cart[index]);
      const cleanAdd = cleanupBookedItems(cartDataCpy.toAdd);
      const cleanCancel = cleanupBookedItems(cartDataCpy.toCancel);
      const foundIndexCartData = cleanCancel.findIndex((item) =>
        some(cleanAdd, (element) => isEqual(item, element))
      );
      if (foundIndexCartData !== -1) {
        const newCancel = cartDataCpy.toCancel;
        newCancel.splice(foundIndexCartData, 1);
        setCartData({ ...cartData, toCancel: newCancel });
      } else {
        setCartData({ ...cartData, toAdd: cartDataCpy.toAdd });
      }
      const bookedItem = cart[index];
      const tmpBookedItem = cloneDeep(bookedItem);
      // check if added items number (of same item) is lower than the actual bookings items (of that item)
      // to not print them, because those have been printed already
      if (bookings.length > 0 && selectedBookingIndex !== null) {
        const cleanBookingItems = cleanupBookedItems(
          bookings[selectedBookingIndex].bookedItems.filter((item) => item.isRefund === false)
        );
        const cleanCart = cleanupBookedItems(cart);
        const countAddedInOldBooking = countBy(cleanBookingItems, (element) =>
          isEqual(element, cleanCart[index])
        ).true;
        if (countAddedInOldBooking != null) {
          const cleanCart2 = cleanupBookedItems(cart.filter((item) => item.isRefund === false));

          const elementIndexCart2 = cleanCart.findIndex((item) =>
            isEqual(item, cleanupBookedItems([cart[index]])[0])
          );

          const countAddedInOldBooking1 = countBy(cleanCart2, (element) =>
            isEqual(element, cleanCart[elementIndexCart2])
          ).true;
          if (countAddedInOldBooking1 != null && countAddedInOldBooking1 < countAddedInOldBooking) {
            tmpBookedItem.isInternallyPrinted = true;
          } else {
            tmpBookedItem.isInternallyPrinted = false;
          }
        } else {
          tmpBookedItem.isInternallyPrinted = false;
        }
      } else {
        tmpBookedItem.isInternallyPrinted = false;
      }

      tmpBookedItem._id = null;
      delete tmpBookedItem._id;
      setCart([...cart, tmpBookedItem]);
    }
  };

  const handleSearch = async (value) => {
    if (value.length > 0) {
      if (selectedCategoryIndex !== 0) {
        setSelectedCategoryIndex(0);
      }
      const searchByTitle = (query) =>
        data.allItems.filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
      setData({ ...data, items: searchByTitle(value) });
    } else {
      setData({ ...data, items: data.allItems });
    }
  };

  const renderSpecialSelections = (item) =>
    item.specialSelections ? (
      item.specialSelections.map((specialSelection, index) => (
        <Fragment key={`bc-ss-${index}`}>
          <div className="customer-item-screen-special-selection-container">
            <Checkbox
              text={specialSelection.name}
              onChange={(event) => onChangeCheckbox(event, index, item)}
              checked={
                itemSettings.specialSelections.length > 0 && itemSettings.specialSelections[index]
              }
              labelStyle={{
                paddingLeft: '10px',
                fontSize: '15px',
                fontWeight: 500,
                wordBreak: 'break-all',
                width: '100%',
              }}
              checkboxStyle={{
                height: 20,
                width: 25,
              }}
            />
            <p style={{ fontSize: '15px', fontWeight: 500, width: 110, textAlign: 'right' }}>
              {`${asCurrency(specialSelection.price / 100)} €`}
            </p>
          </div>
        </Fragment>
      ))
    ) : (
      <></>
    );

  const renderOptionGroups = (item) => {
    if (
      itemSettings.optionGroups.length === 0 &&
      item.optionGroups &&
      item.optionGroups.length > 0 &&
      itemSettings.optionGroups.length !== item.optionGroups.length
    ) {
      itemSettings.optionGroups = item.optionGroups;
      let newPrice = 0;
      itemSettings.optionGroups.forEach((optionGroup) => {
        optionGroup.options.forEach((option, index) => {
          if (index === 0) {
            // eslint-disable-next-line no-param-reassign
            option.checked = true;
            newPrice += option.price;
          } else {
            // eslint-disable-next-line no-param-reassign
            option.checked = false;
          }
        });
      });
      setItemSettings({
        ...itemSettings,
        optionGroups: itemSettings.optionGroups,
        totalPrice: itemSettings.totalPrice + newPrice,
      });
    }
    return item.optionGroups ? (
      item.optionGroups.map((optionGroup, index) => (
        <Fragment key={`bc-og-${index}`}>
          <p className="customer-item-screen-subtitles">{optionGroup.title}</p>
          {optionGroup.options.map((option, idx) => (
            <div className="customer-item-screen-special-selection-container">
              <Radio
                label={option.name}
                onChange={(event) => onChangeRadio(event, index, idx, item)}
                checked={
                  itemSettings.optionGroups &&
                  itemSettings.optionGroups.length > 0 &&
                  itemSettings.optionGroups[index].options[idx].checked === true
                }
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  wordBreak: 'break-all',
                  width: '100%',
                  height: 32,
                }}
              />
              <p style={{ fontSize: '14px', fontWeight: 500, width: 110, textAlign: 'right' }}>
                {`${asCurrency(option.price / 100)} €`}
              </p>
            </div>
          ))}
        </Fragment>
      ))
    ) : (
      <></>
    );
  };

  const parseCart = () => {
    const cleanedUpBookedItems = cart.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (!item._id) {
        newItem._id = item._id;
      }
      if (item.notes) newItem.notes = item.notes;
      if (item.pfand !== null) newItem.pfand = item.pfand;
      return newItem;
    });
    return cart.map((bookedItem, index) => (
      <Fragment key={`cart-checkout-${index}`}>
        {bookedItem.isRefund === false ? (
          <>
            {index ===
            findLastIndex(cleanedUpBookedItems, (element) =>
              isEqual(element, cleanedUpBookedItems[index])
            ) ? (
              <>
                <div className="checkout-screen-bookedItem-flex" style={{ fontWeight: 500 }}>
                  <div style={{ display: 'flex', width: '74%' }}>
                    <p
                      style={{
                        fontSize: '12px',
                        fontWeight: bookedItem._id ? 600 : 800,
                        width: 13,
                        color: `#7268cd`,
                        paddingTop: 1,
                      }}
                    >
                      {countElement(cart, index)}
                    </p>
                    <p
                      style={{
                        paddingLeft: 6,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 14,
                        fontWeight: bookedItem._id ? 500 : 700,
                      }}
                    >
                      {` ${bookedItem.itemTitle}`}
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      fontSize: 14,
                      fontWeight: bookedItem._id ? 500 : 700,
                    }}
                  >
                    {`${asCurrency(bookedItem.amount / 100)} €`}
                  </div>
                </div>
                <div className="checkout-screen-specialSelections-count-flex-terminal">
                  <div className="checkout-screen-specialSelections">
                    {bookedItem.notes ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 0,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">H</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${bookedItem.notes}`}
                          </p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {bookedItem.options &&
                      bookedItem.options.map((option) => (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              gap: 4,
                              marginLeft: 0,
                              marginBottom: 4,
                              alignItems: 'center',
                            }}
                          >
                            <p className="bookings-extras-options-indicator">O</p>
                            <p
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              {` ${option.name}`}
                            </p>
                          </div>
                        </>
                      ))}
                    {bookedItem.specialSelections.map((specialSelection) => (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 0,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">E</p>
                          <p
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {` ${specialSelection.specialSelectionTitle}`}
                          </p>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="bookings-create-plus-minus" style={{ gap: 4 }}>
                    <div
                      style={{
                        border:
                          (cartData.toAdd.length > 0 &&
                            selectedBookingIndex !== null &&
                            cleanupBookedItems(cartData.toAdd).find((tItem) =>
                              isEqual(cleanupBookedItems(cart)[index], tItem)
                            )) ||
                          selectedBookingIndex === null
                            ? `1px solid #7268cd`
                            : `1px solid #bbb`,
                        width: '24px',
                        borderRadius: '12px',
                        textAlign: 'center',
                        height: '24px',
                        marginRight: '6px',
                        fontSize: '16px',
                        fontWeight: '600',
                        backgroundColor: 'white',
                        color:
                          (cartData.toAdd.length > 0 &&
                            selectedBookingIndex !== null &&
                            cleanupBookedItems(cartData.toAdd).find((tItem) =>
                              isEqual(cleanupBookedItems(cart)[index], tItem)
                            )) ||
                          selectedBookingIndex === null
                            ? `#7268cd`
                            : '#bbb',
                        fontStyle: 'normal',
                        cursor: 'pointer',
                        marginBottom: '22px',
                      }}
                      onClick={() => {
                        if (cartData.toAdd.length > 0 && selectedBookingIndex !== null) {
                          const isInToAdd = cleanupBookedItems(cartData.toAdd).find((tItem) =>
                            isEqual(cleanupBookedItems(cart)[index], tItem)
                          );

                          if (isInToAdd) {
                            subtractBookedItem(index);
                          }
                        } else if (selectedBookingIndex === null) {
                          subtractBookedItem(index);
                        }
                      }}
                    >
                      -
                    </div>
                    <div
                      style={{
                        border: `1px solid #7268cd`,
                        borderRadius: '12px',
                        width: '24px',
                        textAlign: 'center',
                        height: '24px',
                        fontSize: '16px',
                        fontWeight: '600',
                        backgroundColor: 'white',
                        color: `#7268cd`,
                        paddingTop: '1px',
                        fontStyle: 'normal',
                        cursor: 'pointer',
                        marginBottom: '22px',
                      }}
                      onClick={() => addSameBookedItem(index)}
                    >
                      +
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Fragment>
    ));
  };

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (data.categories != null) {
      const category = data.categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const parseTablesAndCategories = () => (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', overflow: 'unset' }}>
          {data.rooms && data.rooms.length > 0 ? (
            <>
              <div
                style={{
                  width: 100,
                  borderRadius: 10,
                  textAlign: 'center',
                  display: 'table',
                  flexShrink: 0,
                  backgroundColor: '#7268cd',
                  color: 'white',
                  cursor: 'pointer',
                  marginRight: 6,
                }}
              >
                <Dropdown
                  className="bookings-create-room-selection-dropdown-container"
                  style={{
                    fontWeight: 700,
                    fontSize: 16,
                    textAlign: 'center',
                    height: '50px',
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    zIndex: 21474836,
                    position: 'relative',
                    textOverflow: 'ellipsis',
                    maxWidth: 160,
                    whiteSpace: 'normal',
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  inline
                  scrolling
                  text={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        width: 100,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        marginBottom: -10,
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      {selectedRoom}
                    </p>
                  }
                >
                  <Dropdown.Menu
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      boxShadow: 'none',
                      padding: 0,
                      borderRadius: 10,
                    }}
                  >
                    <Dropdown.Item
                      className="bookingscreate-dropdown-item"
                      onClick={(x, d) => {
                        setSelectedRoom(d.value);
                        setData({ ...data, tables: data.allTables });
                        setSelectedTableIndex(0);
                        setCheckedSelectedTableIndex(null);
                        setBookings([]);
                        setCart([]);
                        setCartData({ toAdd: [], toCancel: [] });
                        setDropdownOptions([]);
                        resetBookingId();
                        setSelectedBookingIndex(null);
                      }}
                      value={i18n.t('allTables')}
                    >
                      <p
                        style={{
                          fontSize: 15,
                          color: 'white',
                          backgroundColor: '#867ecc',
                          height: 40,
                          textAlign: 'center',
                          paddingTop: 10,
                          paddingRight: 2,
                          paddingLeft: 2,
                          borderRadius: 10,
                          marginBottom: 5,
                          fontWeight: 600,
                          textOverflow: 'ellipsis',
                          width: 100,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          boxShadow: 'white 0px 0px 2px',
                          marginTop: 4,
                        }}
                      >
                        {i18n.t('allTables')}
                      </p>
                    </Dropdown.Item>
                    {data.rooms &&
                      data.rooms.map((room) => (
                        <>
                          <Dropdown.Item
                            className="bookingscreate-dropdown-item"
                            onClick={(x, d) => {
                              setSelectedRoom(d.value);
                              const newTables = data.allTables.filter((table) =>
                                room.tables.some((tTable) => table.number === tTable.number)
                              );
                              setSelectedTableIndex(0);
                              setCheckedSelectedTableIndex(null);
                              setBookings([]);
                              setSelectedBookingIndex(null);
                              setCart([]);
                              setCartData({ toAdd: [], toCancel: [] });
                              setDropdownOptions([]);
                              resetBookingId();
                              setData({ ...data, tables: newTables });
                            }}
                            value={room.name}
                          >
                            <p
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                                color: 'white',
                                backgroundColor: '#867ecc',
                                height: 40,
                                textAlign: 'center',
                                paddingTop: 10,
                                paddingRight: 2,
                                paddingLeft: 2,
                                borderRadius: 10,
                                marginBottom: 5,
                                marginTop: 8,
                                textOverflow: 'ellipsis',
                                width: 100,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                boxShadow: 'white 0px 0px 2px',
                              }}
                            >
                              {room.name}
                            </p>
                          </Dropdown.Item>
                        </>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            style={{ display: 'flex', height: 50, gap: 6, padding: 0, overflowY: 'hidden' }}
            className="bookings-create-tables-items-container"
          >
            {data.tables.map((table, index) => (
              <div
                id={index === selectedTableIndex ? 'selectedtable' : ''}
                key={`bc-tbl-${index}`}
                style={{
                  width: 60,
                  borderRadius: 10,
                  textAlign: 'center',
                  display: 'table',
                  flexShrink: 0,
                  backgroundColor: index === selectedTableIndex ? '#7268cd' : '#c5c9cf',
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (selectedTableIndex !== index) {
                    setCart([]);
                    setCartData({ toAdd: [], toCancel: [] });
                    setSelectedTableIndex(index);
                    setBookings([]);
                    setSelectedBookingIndex(null);
                    setDropdownOptions([]);
                    resetBookingId();
                  }
                }}
              >
                <p
                  style={{
                    height: '100%',
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    fontWeight: 700,
                    fontSize: 17,
                    padding: 5,
                  }}
                >
                  {table.number ? `${table.number}` : table.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{ display: 'flex', height: 50, gap: 10, marginTop: 10, overflowY: 'hidden' }}
          className="bookings-create-tables-items-container"
        >
          {data.categories.map((category, index) => (
            <Fragment key={`bc-cat-${index}`}>
              <div
                onClick={() => selectCategory(index)}
                style={{
                  width: 100,
                  borderRadius: 10,
                  textAlign: 'center',
                  display: 'table',
                  flexShrink: 0,
                  backgroundColor: index === selectedCategoryIndex ? '#7268cd' : '#c5c9cf',
                  color: 'white',
                  cursor: 'pointer',
                }}
              >
                <p
                  style={{
                    height: '100%',
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    fontWeight: 700,
                    fontSize: 15,
                    padding: 5,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getCategoryTranslation(category)}
                </p>
              </div>
            </Fragment>
          ))}
        </div>
        <div>
          {showSearch === true ? (
            <>
              <div
                style={{
                  paddingTop: 6,
                  paddingBottom: 0,
                  paddingRight: 6,
                  width: '100%',
                }}
              >
                <Input
                  ref={searchRef}
                  placeholder={i18n.t('bookingSearch')}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  value={searchValue}
                  icon={
                    searchValue.length > 0 ? (
                      <Icon
                        name="delete"
                        link
                        onClick={() => {
                          setSearchValue('');
                          handleSearch('');
                        }}
                      />
                    ) : (
                      <Icon name="search" />
                    )
                  }
                  className="search-bookings-create-input"
                  fluid
                  autoFocus
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <Grid
            columns={2}
            style={{
              alignContent: 'flex-start',
              paddingBottom: 140,
              marginTop: 10,
              marginLeft: 0,
              marginRight: 0,
              gap: 4,
              height: 'calc(100vh - 260px)',
              overflowY: 'scroll',
              overflowX: 'hidden',
              justifyContent: 'space-between',
            }}
            className="bookings-create-items-scroll"
          >
            {data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => (
                <Fragment key={`bc-itms-${index}`}>
                  {(item.availability === 'terminal' || item.availability === 'all') && (
                    <Grid.Column style={{ height: 92, padding: 0, width: '49%' }}>
                      <div
                        ref={observe}
                        style={{
                          height: 90,
                          borderRadius: 12,
                          padding: 10,
                          fontWeight: 700,
                          fontSize: 16,
                          wordBreak: 'break-all',
                          textAlign: 'center',
                          border: '1px solid #7268cd',
                          background: 'white',
                          color: '#7268cd',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setItemSettings({
                            totalPrice: item.price,
                            specialSelections: [],
                            notes: '',
                            optionGroups: [],
                          });
                          setItemDetailsModalOptions({ open: true, item });
                        }}
                      >
                        <div style={{ margin: '0 auto' }}>
                          <p
                            style={{
                              display: '-webkit-box',
                              lineClamp: 1,
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              whiteSpace: 'initial',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 1,
                              fontSize: 17,
                            }}
                          >
                            {item && item.title ? item.title : ''}
                          </p>
                          <p
                            style={{
                              width: '100%',
                              left: 0,
                              color: 'black',
                            }}
                          >
                            {item && item.price ? `${asCurrency(item.price / 100)}€` : ''}
                          </p>
                        </div>
                      </div>
                    </Grid.Column>
                  )}
                </Fragment>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );

  const showTerminalConnectionImpossibleError = (message) => {
    showToast({
      message: `${i18n.t('cardReaderError1')}\n ${
        `${i18n.t('newPrinterErrorTitle')}: ${message}` || ''
      }`,
      title: i18n.t('cardReader'),
      acknowledgable: true,
      id: 'TerminalConnectionError',
    });
  };

  const getConnectionToken = async () => {
    try {
      const res = await createTerminalReaderConnectionToken(token);
      if (res && res.success) {
        return res.secret;
      }
      showTerminalConnectionImpossibleError();
      return null;
    } catch (error) {
      showTerminalConnectionImpossibleError();
      return null;
    }
  };

  const onUnexpectedReaderDisconnect = () => {
    setErrorWarningModalOptions({
      open: true,
      message: i18n.t('cardReaderError2'),
    });
  };

  const createTerminal = async () => {
    const StripeTerminal = await loadStripeTerminal();
    const terminal = StripeTerminal.create({
      onFetchConnectionToken: getConnectionToken,
      onUnexpectedReaderDisconnect,
    });
    setStripeTerminal(terminal);
  };

  const discoverTerminalReaders = async () => {
    try {
      setIsDiscoveringTerminal(true);
      const discoverResult = await stripeTerminal.discoverReaders({
        location: shop.stripeLocationId,
      });
      setIsDiscoveringTerminal(false);
      if (discoverResult.error) {
        showTerminalConnectionImpossibleError(
          discoverResult.error ? discoverResult.error.message : ''
        );
      } else if (discoverResult.discoveredReaders.length === 0) {
        showTerminalConnectionImpossibleError(i18n.t('cardReaderError3'));
      } else {
        setTerminals(discoverResult.discoveredReaders);
      }
    } catch (error) {
      showToast({
        error: true,
        message: `${i18n.t('cardReaderError4')}${error}`,
        title: i18n.t('newPrinterErrorTitle'),
        id: `CardReaderError-discoverReaders`,
      });
    } finally {
      setIsDiscoveringTerminal(false);
    }
  };

  const connectReader = async (terminal) => {
    try {
      setIsConnectingToTerminal(true);
      const connectResult = await stripeTerminal.connectReader(terminal, {
        fail_if_in_use: true,
      });
      if (connectResult.error) {
        showTerminalConnectionImpossibleError(
          connectResult.error ? connectResult.error.message : ''
        );
        setIsConnectingToTerminal(false);
      } else {
        setIsConnectingToTerminal(false);
        const reader = cloneDeep(stripeTerminal);
        setStripeTerminal(reader);
        setReaderDisplay();
        setTerminalModalOptions({ open: true });
      }
    } catch (error) {
      setIsConnectingToTerminal(false);
      showToast({
        error: true,
        message: `${i18n.t('cardReaderError5')}${error}`,
        title: i18n.t('newPrinterErrorTitle'),
        id: `CardReaderError-connectReader`,
      });
    }
  };

  const setReaderDisplay = () => {
    const cleanedUpBookedItems = cart
      .filter((item) => item.isRefund === false)
      .map((item) => {
        const newItem = {
          itemTitle: item.itemTitle,
          categoryName: item.categoryName,
          specialSelections: item.specialSelections.map((special) => ({
            specialSelectionId: special.specialSelectionId,
            specialSelectionTitle: special.specialSelectionTitle,
            specialSelectionPrice: special.specialSelectionPrice,
          })),
          options:
            item.options && item.options.length > 0
              ? item.options.map((option) => ({ name: option.name, price: option.price }))
              : null,
          amount: item.amount,
          taxRate: item.taxRate,
          isRefund: item.isRefund,
        };
        if (item.notes) newItem.notes = item.notes;
        if (item.pfand !== null) newItem.pfand = item.pfand;
        return newItem;
      });
    const readerDisplayCart = cleanedUpBookedItems.map((item, index) => {
      if (
        index ===
        findLastIndex(cleanedUpBookedItems, (element) =>
          isEqual(element, cleanedUpBookedItems[index])
        )
      ) {
        return {
          description: item.itemTitle,
          amount: item.amount,
          quantity: countElement(cleanedUpBookedItems, index),
        };
      }
      return null;
    });
    stripeTerminal.setReaderDisplay({
      type: 'cart',
      cart: {
        line_items: readerDisplayCart.filter((value) => value),
        total: cart.reduce((sum, item) => sum + (item.isRefund === false ? item.amount : 0), 0),
        currency: 'eur',
      },
    });
  };

  const payByCard = async () => {
    const bookedItems = cart;
    const tableDTO =
      data.tables[selectedTableIndex] &&
      (data.tables[selectedTableIndex].description === 'In-House' ||
        data.tables[selectedTableIndex].description === 'Take-Away')
        ? {
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
            tableDescription: data.tables[selectedTableIndex].description,
          }
        : {
            tableId: data.tables[selectedTableIndex]._id.toString(),
            tableNumber: data.tables[selectedTableIndex].number,
            tableDescription: data.tables[selectedTableIndex].description,
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
          };

    payByCardFlow(bookedItems, tableDTO);
  };

  const payByCardFlow = async (bookedItems, tableDTO) => {
    try {
      setIsSettingUpTerminal(true);
      const res = await createCardTerminalPayment(
        shop._id,
        bookedItems,
        null,
        tableDTO,
        bookings.length > 0 && selectedBookingIndex !== null
          ? bookings[selectedBookingIndex]._id
          : null,
        token
      );
      if (res && res.success) {
        const collectPaymentMethodResult = await stripeTerminal.collectPaymentMethod(
          res.clientSecret,
          {
            config_override: {
              skip_tipping: shop.tipsAllowed !== true,
            },
          }
        );
        if (collectPaymentMethodResult.error) {
          console.log(collectPaymentMethodResult.error);
          if (collectPaymentMethodResult.error.code !== 'canceled') {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentError1'),
            });
            setIsSettingUpTerminal(false);
            setIsLoadingPaymentData(false);
          }
        } else {
          setIsLoadingPaymentData(true);
          const processPaymentResult = await stripeTerminal.processPayment(
            collectPaymentMethodResult.paymentIntent
          );
          if (processPaymentResult.error) {
            console.log(processPaymentResult.error);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentError1'),
            });
            setIsSettingUpTerminal(false);
            setIsLoadingPaymentData(false);
          } else if (processPaymentResult.paymentIntent) {
            const fulfillCardTerminalPaymentResponse = await fulfillCardTerminalPayment(
              shop._id,
              tableDTO,
              res.paymentIntentId,
              processPaymentResult.paymentIntent.charges.data[0].payment_method_details
                .card_present,
              processPaymentResult.paymentIntent.charges.data[0].payment_method,
              bookedItems,
              bookings.length > 0 && selectedBookingIndex !== null
                ? bookings[selectedBookingIndex]._id
                : null,
              processPaymentResult.paymentIntent.amount_details.tip.amount,
              cartData.toAdd,
              cartData.toCancel,
              token
            );
            if (fulfillCardTerminalPaymentResponse && fulfillCardTerminalPaymentResponse.success) {
              if (
                shop.printers &&
                shop.printers.length > 0 &&
                user &&
                user.isPrinting === true &&
                shop.autoPrintCustomer === true
              ) {
                printCustomerBon(fulfillCardTerminalPaymentResponse.booking);
              }
              closeModal(true);
            } else {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('paymentError1'),
              });
              setIsSettingUpTerminal(false);
              setIsLoadingPaymentData(false);
            }
          }
        }
        setIsSettingUpTerminal(false);
        setIsLoadingPaymentData(false);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentError1'),
        });
        setIsLoadingPaymentData(false);
        setIsSettingUpTerminal(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('paymentError1'),
      });
      setIsLoadingPaymentData(false);
      setIsSettingUpTerminal(false);
    }
  };

  const onSplitBooking = async () => {
    try {
      setIsLoadingSplitBooking(true);
      const res = await splitBooking(
        bookingsSplitModal.booking._id,
        oldBookedItems,
        newBookedItems,
        token
      );
      setIsLoadingSplitBooking(false);
      if (res && res.success) {
        setOldBookedItems([]);
        setNewBookedItems([]);
        setBookingsSplitModalOptions({ open: false, booking: null });
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('bookingSplitError'),
        });
      }
    } catch (error) {
      setIsLoadingSplitBooking(false);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('bookingSplitError')} ${error}`,
      });
    }
  };

  if (shop && user && open === true) {
    return (
      <>
        <Modal
          open={open}
          dimmer="blurring"
          closeOnEscape
          closeOnDimmerClick
          size="fullscreen"
          className="bookingscreatemodal"
        >
          <Modal.Header style={{ borderBottom: 'none', backgroundColor: '#F5F5F9' }}>
            <Dropdown
              placeholder={dropdownOptions.length > 0 ? i18n.t('bookingsCreateModalTitle') : ''}
              style={{ fontSize: 17 }}
              options={dropdownOptions}
              clearable
              icon={dropdownOptions.length > 0 ? 'dropdown' : ''}
              value={selectedBookingIndex}
              onChange={(_, data) => {
                if (data.value !== null && data.value.length !== 0) {
                  setSelectedBookingIndex(data.value);
                  setCart(bookings[data.value].bookedItems);
                  setCartData({ toAdd: [], toCancel: [] });
                } else {
                  setSelectedBookingIndex(null);
                  setCart([]);
                  setCartData({ toAdd: [], toCancel: [] });
                }
              }}
            />
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(true)}
              floated="right"
              className="settings-close"
              circular
            />
            <Button
              icon={{ name: 'search', color: showSearch ? 'grey' : '', inverted: showSearch }}
              color={showSearch ? 'teal' : 'black'}
              basic={!showSearch}
              style={{ color: 'red' }}
              className="bookings-btn-split-empl"
              onClick={() => {
                if (searchRef.current) {
                  searchRef.current.focus();
                }
                setShowSearch(!showSearch);
                setSearchValue('');
                setData({ ...data, items: data.allItems });
              }}
              circular
            />
          </Modal.Header>
          <Modal.Content
            className="bookings-create-content unselectable"
            style={{ padding: 12, paddingTop: '0 !important' }}
          >
            {isLoadingInitialData === true && (
              <>
                <Loader
                  size="massive"
                  active={isLoadingInitialData}
                  inverted
                  className="bookings-create-loader"
                />
              </>
            )}
            {isLoadingInitialData === false && data.tables.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                }}
                className="bookings-create-content-mobile"
              >
                {parseTablesAndCategories()}
              </div>
            )}
            {isLoadingInitialData === false && data.tables.length === 0 && (
              <>
                <Modal dimmer="blurring" size="tiny" open>
                  <Modal.Header style={{ borderBottom: 'none' }}>
                    <p>{i18n.t('newPrinterErrorTitle')}</p>
                  </Modal.Header>
                  <Modal.Content className="unselectable">
                    <p style={{ fontWeight: 600 }}>{i18n.t('tableNotAvailableError2')}</p>
                  </Modal.Content>
                  <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
                    <Button content="OK" color="teal" onClick={onFinalErrorWarningModalOk} />
                  </Modal.Actions>
                </Modal>
              </>
            )}
            {cart && cart.length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  height: 50,
                  left: 10,
                  right: 10,
                  backgroundColor: '#7268cd',
                  bottom: 20,
                  borderRadius: 12,
                  textAlign: 'center',
                  paddingTop: 12,
                  color: 'white',
                  fontWeight: 700,
                  fontSize: 18,
                  cursor: 'pointer',
                }}
                onClick={() => setCartModalOptions({ open: true })}
              >
                {`${`${i18n.t('orderTitle')} (`}${
                  cart.filter((item) => item.isRefund === false).length
                })`}
              </div>
            )}
          </Modal.Content>
        </Modal>
        <Modal
          open={itemDetailsModalOptions.open}
          size="small"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{
              background: '#f5f5f9',
              borderBottom: 'none',
              wordBreak: 'break-all',
              width: '95%',
            }}
          >
            {itemDetailsModalOptions.item
              ? `${itemDetailsModalOptions.item.title} - ${getCategoryTranslationForName(
                  itemDetailsModalOptions.item.categoryName
                )}`
              : ''}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setItemSettings({
                  totalPrice: 0,
                  specialSelections: [],
                  notes: '',
                  optionGroups: [],
                });
                setItemDetailsModalOptions({ open: false, item: null });
              }}
              floated="right"
              className="settings-close"
              circular
            />
          </Modal.Header>
          <Modal.Content scrolling style={{ background: '#f5f5f9' }} className="unselectable">
            {itemDetailsModalOptions.item && (
              <>
                {itemDetailsModalOptions.item.optionGroups &&
                  itemDetailsModalOptions.item.optionGroups.length > 0 && (
                    <>{renderOptionGroups(itemDetailsModalOptions.item)}</>
                  )}
                {itemDetailsModalOptions.item.specialSelections &&
                  itemDetailsModalOptions.item.specialSelections.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">
                        {i18n.t('itemsSpecialSelections')}
                      </p>
                      {renderSpecialSelections(itemDetailsModalOptions.item)}
                    </>
                  )}
                {itemDetailsModalOptions.item.ingredients &&
                  itemDetailsModalOptions.item.ingredients.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">{i18n.t('itemsIngredients')}</p>
                      {renderIngredients(itemDetailsModalOptions.item)}
                    </>
                  )}
                {itemDetailsModalOptions.item.allergens &&
                  itemDetailsModalOptions.item.allergens.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">{i18n.t('itemsAllergens')}</p>
                      {renderAllergens(itemDetailsModalOptions.item)}
                    </>
                  )}
                <p className="customer-item-screen-subtitles">{i18n.t('appNotes')}</p>
                <TextArea
                  placeholder={i18n.t('appDescription')}
                  className="customer-item-screen-textarea"
                  defaultValue={itemSettings.notes}
                  maxLength="160"
                  onChange={(e) => {
                    setItemSettings({
                      ...itemSettings,
                      notes: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div
              className="customer-items-checkout-button"
              style={{
                backgroundColor: '#7268cd',
                cursor: 'pointer',
                boxShadow: `#7268cd 0px 4px 12px`,
                position: 'relative',
                width: '100%',
                margin: '10px 0px',
                height: 50,
              }}
              onClick={() => {
                if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
                  const bookedItem = {
                    itemId: itemDetailsModalOptions.item._id,
                    categoryName: itemDetailsModalOptions.item.categoryName,
                    itemTitle: itemDetailsModalOptions.item.title,
                    isRefund: false,
                    specialSelections: itemDetailsModalOptions.item.specialSelections
                      .filter((_, index) => itemSettings.specialSelections[index] === true)
                      .map((specialSelection) => ({
                        specialSelectionId: specialSelection._id,
                        specialSelectionTitle: specialSelection.name,
                        specialSelectionPrice: specialSelection.price,
                      })),
                    options: itemSettings.optionGroups.map((optionGroup) => ({
                      name: optionGroup.options.find((option) => option.checked === true).name,
                      price: optionGroup.options.find((option) => option.checked === true).price,
                    })),
                    notes: itemSettings.notes,
                    amount: Math.round(itemSettings.totalPrice),
                    taxRate:
                      data.tables[selectedTableIndex].isTakeAway === 'true' ||
                      data.tables[selectedTableIndex].isTakeAway === true
                        ? itemDetailsModalOptions.item.takeAwayTaxRate
                        : itemDetailsModalOptions.item.inHouseTaxRate,
                  };
                  if (itemDetailsModalOptions.item.pfand !== null) {
                    bookedItem.pfand = itemDetailsModalOptions.item.pfand;
                  }
                  const { toAdd } = cartData;
                  toAdd.push(bookedItem);
                  setCartData({ ...cartData, toAdd });
                  setCart([...cart, bookedItem]);
                  setItemDetailsModalOptions({ open: false, item: null });
                  setItemSettings({
                    totalPrice: 0,
                    specialSelections: [],
                    notes: '',
                    optionGroups: [],
                  });
                }
              }}
            >
              {itemDetailsModalOptions.item ? `${asCurrency(itemSettings.totalPrice / 100)} €` : ''}
            </div>
          </Modal.Actions>
        </Modal>
        <Modal
          open={cashPaymentModalOptions.open}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Content style={{ background: '#f5f5f9' }} className="unselectable">
            {cashPaymentModalOptions.amount !== null && (
              <>
                <p
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingTop: 4,
                    fontSize: 24,
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  {`${asCurrency(cashPaymentModalOptions.amount / 100)} €`}
                </p>
                <div style={{ height: 60 }}>
                  <span
                    style={{
                      float: 'left',
                      fontSize: 14,
                      fontWeight: 700,
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: 52,
                    }}
                  >
                    {i18n.t('given')}
                  </span>
                  <CurrencyInput
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    disableGroupSeparators
                    allowNegativeValue={false}
                    readOnly
                    id="rckgld"
                    name="rckgld"
                    key="rckgld"
                    maxLength={8}
                    defaultValue={0}
                    value={numPadNumChange}
                    style={{
                      float: 'right',
                      outline:
                        currentInputField === inputFieldOptions.change
                          ? '1px solid #7268cd'
                          : 'none',
                      fontSize: 22,
                      textAlign: 'right',
                      fontWeight: 700,
                      padding: 10,
                      fontFamily: 'Montserrat',
                      borderRadius: 16,
                      width: 170,
                      marginBottom: 12,
                      border: 'none',
                    }}
                    onFocus={() => {
                      setCurrentInputField(inputFieldOptions.change);
                    }}
                  />
                </div>
                {shop.tipsAllowed === true && (
                  <div style={{ height: 60 }}>
                    <span
                      style={{
                        float: 'left',
                        fontSize: 14,
                        fontWeight: 700,
                        display: 'inline-flex',
                        alignItems: 'center',
                        height: 52,
                      }}
                    >
                      {i18n.t('tips2')}
                    </span>
                    <CurrencyInput
                      intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                      disableGroupSeparators
                      allowNegativeValue={false}
                      readOnly
                      id="trkgld"
                      name="trkgld"
                      key="trkgld"
                      maxLength={8}
                      defaultValue={0}
                      value={numPadNumTips}
                      style={{
                        float: 'right',
                        outline:
                          currentInputField === inputFieldOptions.tips
                            ? '1px solid #7268cd'
                            : 'none',
                        fontSize: 22,
                        textAlign: 'right',
                        fontWeight: 700,
                        padding: 10,
                        fontFamily: 'Montserrat',
                        borderRadius: 16,
                        width: 170,
                        marginBottom: 12,
                        border: 'none',
                      }}
                      onFocus={(event) => {
                        setCurrentInputField(inputFieldOptions.tips);
                      }}
                    />
                  </div>
                )}
                <div
                  style={{ width: '100%', height: 1, backgroundColor: '#ccc', float: 'right' }}
                />
                <p
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingTop: 10,
                    fontSize: 24,
                    fontWeight: 700,
                    float: 'right',
                  }}
                >
                  <span style={{ float: 'left', fontSize: 14 }}>{i18n.t('change2')}</span>
                  <span style={{ maxWidth: 20 }}>
                    {numPadNumChange === null ||
                    numPadNumChange === 0 ||
                    numPadNumChange === '0' ||
                    numPadNumChange === '0,'
                      ? '0 €'
                      : `${asCurrency(
                          calculateChange(
                            parseFloat(numPadNumChange.replace(',', '.')) -
                              parseFloat(numPadNumTips.replace(',', '.'))
                          )
                        )} €`}
                  </span>
                </p>
                <NumPad
                  maxDigits={8}
                  currentValue={
                    currentInputField === inputFieldOptions.change ? numPadNumChange : numPadNumTips
                  }
                  onChange={(num) => {
                    if (num.length === 0) {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange('0')
                        : setNumPadNumTips('0');
                    } else {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange(num)
                        : setNumPadNumTips(num);
                    }
                  }}
                />
              </>
            )}
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: isLoadingPaymentData === true ? '1px solid #ddd' : '1px solid red',
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  color: isLoadingPaymentData === true ? '#ddd' : 'red',
                  padding: 15,
                  fontSize: 17,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    setCashPaymentModalOptions({ open: false, amount: 0 });
                    setCardPaymentModalOptions({ open: false });
                    setIsLoadingPaymentData(false);
                  }
                  setNumPadNumChange('0');
                  setNumPadNumTips('0');
                }}
              >
                {i18n.t('appPayCancel')}
              </div>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: '#7268cd',
                  cursor: 'pointer',
                  boxShadow: `#7268cd 0px 4px 12px`,
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  padding: 15,
                  fontSize: 18,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    payByCash();
                  }
                  setNumPadNumChange('0');
                  setNumPadNumTips('0');
                }}
              >
                {isLoadingPaymentData ? (
                  <>
                    <Loader active={isLoadingPaymentData} className="bookings-create-loader" />
                  </>
                ) : (
                  <>{i18n.t('bookingsModalPay')}</>
                )}
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Modal
          open={cardPaymentModalOptions.open}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{ borderBottom: 'none', padding: 12, backgroundColor: '#F5F5F9', height: 42 }}
          >
            {i18n.t('cardReaders')}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => setCardPaymentModalOptions({ open: false })}
              floated="right"
              className="settings-close"
              circular
            />
          </Modal.Header>
          <Modal.Content style={{ background: '#f5f5f9' }} className="unselectable">
            {isDiscoveringTerminal ? (
              <>
                <Loader
                  active={isDiscoveringTerminal}
                  inline="centered"
                  className="bookings-create-loader"
                />
              </>
            ) : (
              <>
                {terminals.length === 0 ? (
                  <p style={{ fontWeight: 600 }}>{i18n.t('cardReaderError3')}</p>
                ) : (
                  <>
                    {terminals.filter((terminal) => terminal.status === 'online').length > 0 ? (
                      <>
                        <p style={{ fontWeight: 600 }}>{i18n.t('chooseCardReader')}</p>
                        <Table selectable style={{ border: 'none', borderRadius: 8 }}>
                          <Table.Body>
                            {terminals
                              .filter((terminal) => terminal.status === 'online')
                              .map((terminal, index) => (
                                <>
                                  <Table.Row
                                    key={`${index}terminalrow`}
                                    onClick={() => connectReader(terminal)}
                                    disabled={isConnectingToTerminal}
                                  >
                                    <Table.Cell key={`${index}terminalcell`}>
                                      {terminal.label}
                                    </Table.Cell>
                                  </Table.Row>
                                </>
                              ))}
                          </Table.Body>
                        </Table>
                      </>
                    ) : (
                      <>
                        <p style={{ fontWeight: 600 }}>{i18n.t('cardReaderError3')}</p>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Modal.Content>
        </Modal>
        <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
          <Modal.Header style={{ borderBottom: 'none' }}>
            <p>{i18n.t('newPrinterErrorTitle')}</p>
          </Modal.Header>
          <Modal.Content>
            <p style={{ fontWeight: 600 }} className="unselectable">
              {errorWarningModalOptions.message}
            </p>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
            <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
          </Modal.Actions>
        </Modal>
        <Modal
          open={payLaterModalOptions.open === true}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Content scrolling style={{ background: '#f5f5f9' }} className="unselectable">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: 4,
                fontSize: 19,
                fontWeight: 700,
                marginBottom: 10,
              }}
            >
              {i18n.t('saveThisOrder')}
            </p>
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  color: 'red',
                  padding: 15,
                  fontSize: 17,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    setPayLaterModalOptions({ open: false });
                  }
                }}
              >
                {i18n.t('bookingEditBookingWarningNo')}
              </div>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: '#7268cd',
                  cursor: 'pointer',
                  boxShadow: `#7268cd 0px 4px 12px`,
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  padding: 15,
                  fontSize: 18,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    payLater();
                  }
                }}
              >
                {isLoadingPaymentData ? (
                  <>
                    <Loader active={isLoadingPaymentData} className="bookings-create-loader" />
                  </>
                ) : (
                  <>{i18n.t('bookingEditBookingWarningYes')}</>
                )}
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Modal
          open={cartModalOptions.open === true}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{ borderBottom: 'none', padding: 12, backgroundColor: '#F5F5F9', height: 42 }}
          >
            {i18n.t('orderTitle')}
            {(user.isOrdering === true || user.isPaying === true) &&
              bookings.length > 0 &&
              selectedBookingIndex != null &&
              bookings[selectedBookingIndex] &&
              bookings[selectedBookingIndex].bookedItems.filter((item) => item.isRefund === false)
                .length > 1 &&
              cart.filter((item) => item.isRefund === false).length > 1 && (
                <Button
                  icon="exchange"
                  color="black"
                  basic
                  onClick={() => {
                    setOldBookedItems(cloneDeep(bookings[selectedBookingIndex].bookedItems));
                    setBookingsSplitModalOptions({
                      open: true,
                      booking: bookings[selectedBookingIndex],
                    });
                  }}
                  floated="left"
                  className="bookings-btn-split-empl"
                  circular
                />
              )}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => setCartModalOptions({ open: false })}
              floated="right"
              className="settings-close"
              circular
            />
          </Modal.Header>
          <Modal.Content style={{ background: '#f5f5f9' }} className="unselectable">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: 4,
                fontSize: 19,
                fontWeight: 700,
                marginBottom: 10,
              }}
            >
              {`${i18n.t('bookingsTable')} ${
                cartModalOptions.open === true && data.tables && data.tables.length > 0
                  ? data.tables[selectedTableIndex].number
                  : ''
              }`}
            </p>
            <div
              style={{
                backgroundColor: 'white',
                padding: 14,
                borderRadius: 15,
                boxShadow: '#7268cd47 0px 5px 15px',
              }}
            >
              <div
                className="bookings-create-mobile-cart"
                style={{ maxHeight: 300, overflowY: 'scroll' }}
              >
                {parseCart()}
              </div>
              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #333',
                  marginTop: 14,
                  marginBottom: 14,
                }}
              />
              <p style={{ width: '100%', textAlign: 'right', fontWeight: 600, fontSize: 17 }}>
                {`${asCurrency(
                  cart.reduce((sum, item) => sum + (item.isRefund === false ? item.amount : 0), 0) /
                    100
                )} €`}
              </p>
            </div>
            <div style={{ gap: 30, marginTop: 60 }}>
              {(user.isOrdering === true || user.isPaying === true) &&
                cart.length > 0 &&
                cartData.toAdd.length > 0 && (
                  <div
                    className="customer-items-checkout-button"
                    style={{
                      backgroundColor: '#7268cd',
                      cursor: 'pointer',
                      boxShadow: `#7268cd 0px 4px 12px`,
                      position: 'relative',
                      width: '100%',
                      margin: '0px 0px',
                      height: 50,
                      verticalAlign: 'middle',
                      padding: 15,
                      fontSize: 18,
                    }}
                    onClick={() => {
                      setPayLaterModalOptions({ open: true });
                    }}
                  >
                    {isLoadingPaymentData ? (
                      <>
                        <Loader active={isLoadingPaymentData} className="bookings-create-loader" />
                      </>
                    ) : (
                      <>{i18n.t('bookingModalEditSave').toUpperCase()}</>
                    )}
                  </div>
                )}
              {user.isPaying === true && cart.length > 0 && (
                <div
                  className="customer-items-checkout-button"
                  style={{
                    backgroundColor: '#7268cd',
                    cursor: 'pointer',
                    boxShadow: `#7268cd 0px 4px 12px`,
                    position: 'relative',
                    width: '100%',
                    margin: '0px 0px',
                    marginTop: 20,
                    height: 50,
                    verticalAlign: 'middle',
                    padding: 15,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    setCashPaymentModalOptions({
                      open: true,
                      amount: cart.reduce(
                        (sum, item) => sum + (item.isRefund === false ? item.amount : 0),
                        0
                      ),
                    });
                  }}
                >
                  {isLoadingPaymentData ? (
                    <>
                      <Loader active={isLoadingPaymentData} className="bookings-create-loader" />
                    </>
                  ) : (
                    <>{i18n.t('cash').toUpperCase()}</>
                  )}
                </div>
              )}
              {user.isPaying === true &&
                cart.length > 0 &&
                cart.reduce((sum, item) => sum + (item.isRefund === false ? item.amount : 0), 0) >
                  50 &&
                ((shop.terminalReaders && shop.terminalReaders.length > 0) ||
                  shop.isCardPaymentFromOtherAllowed === true) && (
                  <div
                    className="customer-items-checkout-button"
                    style={{
                      backgroundColor: '#7268cd',
                      cursor: 'pointer',
                      boxShadow: `#7268cd 0px 4px 12px`,
                      position: 'relative',
                      width: '100%',
                      margin: '0px 0px',
                      marginTop: 20,
                      height: 50,
                      verticalAlign: 'middle',
                      padding: 15,
                      fontSize: 18,
                    }}
                    onClick={() => {
                      if (shop.isCardPaymentFromOtherAllowed === false) {
                        discoverTerminalReaders();
                        setCardPaymentModalOptions({
                          open: true,
                        });
                      }
                      if (shop.isCardPaymentFromOtherAllowed === true) {
                        setTerminalModalOptions({ open: true });
                        setCurrentInputField(inputFieldOptions.tips);
                      }
                    }}
                  >
                    {isLoadingPaymentData ? (
                      <>
                        <Loader active={isLoadingPaymentData} className="bookings-create-loader" />
                      </>
                    ) : (
                      <>{i18n.t('card').toUpperCase()}</>
                    )}
                  </div>
                )}
            </div>
          </Modal.Content>
        </Modal>
        <Modal
          open={terminalModalOptions.open}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Content
            scrolling
            style={{ background: '#f5f5f9', paddingTop: 10, paddingBottom: 10, maxHeight: '80vh' }}
            className="unselectable"
          >
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: 4,
                fontSize: 17,
                fontWeight: 700,
                marginBottom: 10,
              }}
            >
              {shop.isCardPaymentFromOtherAllowed === false
                ? i18n.t('warningCardPayment1')
                : i18n.t('warningCardPayment2')}
            </p>
            {shop.isCardPaymentFromOtherAllowed === true ? (
              <p style={{ fontSize: 12, width: '100%', textAlign: 'center', paddingBottom: 6 }}>
                <span style={{ fontStyle: 'italic', fontWeight: 700 }}>moaby </span>
                {i18n.t('warningCardPayment3')}
              </p>
            ) : (
              <></>
            )}
            {shop.tipsAllowed === true && shop.isCardPaymentFromOtherAllowed === true && (
              <>
                <div style={{ height: 60 }}>
                  <span
                    style={{
                      float: 'left',
                      fontSize: 14,
                      fontWeight: 700,
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: 52,
                    }}
                  >
                    {i18n.t('tips2')}
                  </span>
                  <CurrencyInput
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    disableGroupSeparators
                    allowNegativeValue={false}
                    readOnly
                    id="trkgld"
                    name="trkgld"
                    key="trkgld"
                    maxLength={8}
                    defaultValue={0}
                    value={numPadNumTips}
                    style={{
                      float: 'right',
                      outline:
                        currentInputField === inputFieldOptions.tips ? '1px solid #7268cd' : 'none',
                      fontSize: 22,
                      textAlign: 'right',
                      fontWeight: 700,
                      padding: 10,
                      fontFamily: 'Montserrat',
                      borderRadius: 16,
                      width: 170,
                      marginBottom: 12,
                      border: 'none',
                    }}
                    onFocus={(event) => {
                      setCurrentInputField(inputFieldOptions.tips);
                    }}
                  />
                </div>
                <div
                  style={{ width: '100%', height: 1, backgroundColor: '#ccc', float: 'right' }}
                />
                <NumPad
                  maxDigits={8}
                  currentValue={
                    currentInputField === inputFieldOptions.change ? numPadNumChange : numPadNumTips
                  }
                  onChange={(num) => {
                    if (num.length === 0) {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange('0')
                        : setNumPadNumTips('0');
                    } else {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange(num)
                        : setNumPadNumTips(num);
                    }
                  }}
                />
              </>
            )}
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: isLoadingPaymentData === true ? '1px solid #ddd' : '1px solid red',
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  color: isLoadingPaymentData === true ? '#ddd' : 'red',
                  padding: 15,
                  fontSize: 17,
                }}
                onClick={() => {
                  setTerminalModalOptions({ open: false });
                  setIsLoadingPaymentData(false);
                  if (shop.isCardPaymentFromOtherAllowed === false) {
                    setIsSettingUpTerminal(false);
                    stripeTerminal.cancelCollectPaymentMethod();
                    stripeTerminal.clearReaderDisplay();
                    stripeTerminal.disconnectReader();
                  }
                }}
              >
                {i18n.t('appPayCancel')}
              </div>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: '#7268cd',
                  cursor: 'pointer',
                  boxShadow: `#7268cd 0px 4px 12px`,
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  padding: 15,
                  fontSize: 18,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    if (
                      isSettingUpTerminal === false &&
                      shop.isCardPaymentFromOtherAllowed === false
                    ) {
                      payByCard();
                    }
                    if (shop.isCardPaymentFromOtherAllowed === true) {
                      payByCardOther();
                    }
                  }
                }}
              >
                {isLoadingPaymentData || isSettingUpTerminal === true ? (
                  <>
                    <Loader active={isLoadingPaymentData} />
                  </>
                ) : (
                  <>{i18n.t('bookingsModalPay')}</>
                )}
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Modal dimmer="blurring" size="tiny" open={bookingsSplitModal.open}>
          <Modal.Header style={{ borderBottom: 'white', backgroundColor: '#f5f5f9' }}>
            {i18n.t('splitOrder')}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setNewBookedItems([]);
                setOldBookedItems([]);
                setBookingsSplitModalOptions({ open: false, booking: null });
              }}
              floated="right"
              circular
              size="big"
              className="tables-close-btn"
            />
          </Modal.Header>
          <Modal.Content
            scrolling
            style={{ paddingTop: 10, backgroundColor: '#f5f5f9' }}
            className="unselectable"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600, fontSize: 16 }}>
                  {i18n.t('orderTitle')}{' '}
                  {bookingsSplitModal.open === true ? bookingsSplitModal.booking.bookingId : ''}
                </p>
                <div
                  style={{
                    padding: 10,
                    borderRadius: 14,
                    width: '100%',
                    height: 200,
                    overflowY: 'scroll',
                    backgroundColor: 'white',
                    boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  }}
                  className="split-booking-no-scrollbar"
                >
                  {bookingsSplitModal.booking &&
                    oldBookedItems.map((item, index) => (
                      <div
                        style={{
                          display: item.isRefund === false ? 'flex' : 'none',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          marginBottom: 12,
                          padding: 4,
                          paddingBottom: 8,
                          borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
                        }}
                        onClick={() => {
                          if (
                            oldBookedItems.filter((item) => item.isRefund === false).length !== 1
                          ) {
                            oldBookedItems.splice(index, 1);
                            setNewBookedItems([...newBookedItems, item]);
                            setOldBookedItems([...oldBookedItems]);
                          }
                        }}
                      >
                        {item.isRefund === false ? (
                          <>
                            <div>
                              <p style={{ fontWeight: 500, margin: 0 }}>{item.itemTitle}</p>
                              {item.specialSelections &&
                                item.specialSelections.length > 0 &&
                                item.specialSelections.map((special) => (
                                  <p style={{ fontStyle: 'italic', paddingLeft: 4, fontSize: 13 }}>
                                    {`· ${special.specialSelectionTitle}`}
                                  </p>
                                ))}
                            </div>
                            <p style={{ fontWeight: 500, textAlign: 'right' }}>
                              {`${asCurrency(item.amount / 100)} €`}
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                </div>
                <p
                  style={{
                    textAlign: 'right',
                    paddingTop: 10,
                    fontSize: 17,
                    paddingRight: 8,
                    fontWeight: 600,
                  }}
                >
                  {`${asCurrency(
                    oldBookedItems
                      .filter((item) => item.isRefund === false)
                      .reduce((sum, { amount }) => sum + amount, 0) / 100
                  )}€`}
                </p>
              </div>
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('newOrder')}</p>
                <div
                  style={{
                    padding: 10,
                    borderRadius: 14,
                    width: '100%',
                    height: 200,
                    overflowY: 'scroll',
                    backgroundColor: 'white',
                    boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  }}
                  className="split-booking-no-scrollbar"
                >
                  {bookingsSplitModal.booking &&
                    newBookedItems.map((item, index) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          marginBottom: 12,
                          padding: 4,
                          paddingBottom: 8,
                          borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
                        }}
                        onClick={() => {
                          newBookedItems.splice(index, 1);
                          setNewBookedItems([...newBookedItems]);
                          setOldBookedItems([...oldBookedItems, item]);
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: 500, margin: 0 }}>{item.itemTitle}</p>
                          {item.specialSelections &&
                            item.specialSelections.length > 0 &&
                            item.specialSelections.map((special) => (
                              <p style={{ fontStyle: 'italic', paddingLeft: 4, fontSize: 13 }}>
                                {`· ${special.specialSelectionTitle}`}
                              </p>
                            ))}
                        </div>
                        <p style={{ fontWeight: 500, textAlign: 'right' }}>
                          {`${asCurrency(item.amount / 100)} €`}
                        </p>
                      </div>
                    ))}
                </div>
                <p
                  style={{
                    textAlign: 'right',
                    paddingTop: 10,
                    fontSize: 17,
                    paddingRight: 8,
                    fontWeight: 600,
                  }}
                >
                  {`${asCurrency(
                    newBookedItems.reduce((sum, { amount }) => sum + amount, 0) / 100
                  )}€`}
                </p>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9', paddingTop: 5 }}>
            <Button
              content={i18n.t('createOrder')}
              color="teal"
              floated="right"
              size="large"
              style={{ borderRadius: 10 }}
              disabled={isSaveNewBookingDisabled || isLoadingSplitBooking}
              onClick={() => {
                onSplitBooking();
              }}
              loading={isLoadingSplitBooking}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
  return <></>;
};

export default BookingsCreateModalMobile;
